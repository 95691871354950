import { useState, useEffect } from 'react';
import type { FormEvent } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Divider,
  Heading,
  Text,
  Spinner,
  Center,
  Button,
  Flex,
  FormControl,
  Textarea,
  FormErrorMessage,
  MailIcon,
  EmailVerificationIcon,
} from '@terminal/design-system';
import { Interview_Feedback_Rating_Choices_Enum } from 'global/types/hasura-tables.generated.types';

import { RadioButton } from 'talent-hub/components';

import {
  StrongNoFaceFeedbackIcon,
  NoFaceFeedbackIcon,
  YesFaceFeedbackIcon,
  StrongYesFaceFeedbackIcon,
} from '../../../../components';

const ViewType = {
  Pending: 'Pending',
  Submitted: 'Submitted',
};

type FeedbackFormData = {
  comments: string;
  rate: string;
};

const validationSchema = Yup.object().shape({
  comments: Yup.string().required('Comments is a required field.'),
  rate: Yup.string().required('Rating is a required field.'),
});

export function CandidateInterviewFeedback({
  candidateName,
  hasInterviewFeedback,
  loading,
  roleName,
  companyName,
  onSubmit,
  onBackToFeedbackCLick,
  isClientReviewer,
  onClickInviteOthers,
}: {
  candidateName: string;
  roleName: string;
  companyName: string;
  hasInterviewFeedback: boolean;
  loading: boolean;
  onSubmit: (rating: string, comments: string) => void;
  onBackToFeedbackCLick: () => void;
  isClientReviewer: boolean;
  onClickInviteOthers: () => void;
}) {
  const [view, setView] = useState(hasInterviewFeedback ? ViewType.Submitted : ViewType.Pending);

  const handleFormSubmit = (values: FeedbackFormData) => {
    onSubmit(values.rate, values.comments);
  };

  const { values, errors, handleChange, handleBlur, touched, handleSubmit } =
    useFormik<FeedbackFormData>({
      initialValues: {
        comments: '',
        rate: '',
      },
      validationSchema,
      validateOnBlur: false,
      enableReinitialize: true,
      onSubmit: handleFormSubmit,
    });

  useEffect(() => {
    setView(hasInterviewFeedback ? ViewType.Submitted : ViewType.Pending);
  }, [hasInterviewFeedback]);

  if (loading) {
    return (
      <Center my={5} height="xs">
        <Spinner />
      </Center>
    );
  }

  if (view === ViewType.Submitted) {
    return (
      <>
        <Divider />
        <Heading variant="heading-3" mt={3}>
          Your feedback has been submitted!
        </Heading>
        <Text mt={2}>
          You can now review other peoples&apos; feedback for this candidate before making a
          decision about {candidateName}.
        </Text>
        <Button size="lg" mt={6} variant="solid" onClick={onBackToFeedbackCLick}>
          Review Feedback
        </Button>
        {!isClientReviewer && (
          <Button size="lg" mt={1} variant="outline" onClick={onClickInviteOthers}>
            <EmailVerificationIcon color="ui.primary" w={4} h={4} mr={3} />
            Invite Others
          </Button>
        )}
      </>
    );
  }

  if (view === ViewType.Pending) {
    return (
      <>
        <Divider />
        <Heading textAlign="center" variant="heading-4" mt={6}>
          Do you recommend {candidateName} for the {roleName} role at {companyName}?
        </Heading>
        <FormControl role="radiogroup" my={6} isInvalid={!!errors.rate}>
          <Flex wrap="wrap" justifyContent="space-around" gridGap="2">
            {[
              {
                Icon: StrongNoFaceFeedbackIcon,
                text: 'Strong No',
                value: Interview_Feedback_Rating_Choices_Enum.StrongNo,
              },
              {
                Icon: NoFaceFeedbackIcon,
                text: 'No',
                value: Interview_Feedback_Rating_Choices_Enum.No,
              },
              {
                Icon: YesFaceFeedbackIcon,
                text: 'Yes',
                value: Interview_Feedback_Rating_Choices_Enum.Yes,
              },
              {
                Icon: StrongYesFaceFeedbackIcon,
                text: 'Strong Yes',
                value: Interview_Feedback_Rating_Choices_Enum.StrongYes,
              },
            ].map(({ Icon, text, value }) => (
              <RadioButton
                key={value}
                value={value}
                onChange={handleChange}
                isChecked={values.rate === value}
                name="rate"
              >
                <Flex flexDir="column" alignItems="center" p={1}>
                  <Icon w={12} h={12} fill="none" />
                  <Text variant="label" textAlign="center" mt={2}>
                    {text}
                  </Text>
                </Flex>
              </RadioButton>
            ))}
          </Flex>
          {errors.rate && <FormErrorMessage mt={1}>{errors.rate}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={touched.comments && !!errors.comments}>
          <Textarea
            id="comments"
            name="comments"
            rows={16}
            placeholder="Provide some information to help your colleagues understand the reason for your feedback."
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comments}
            aria-label="Comments"
          />
          {errors.comments && <FormErrorMessage>{errors.comments}</FormErrorMessage>}
        </FormControl>
        <Flex mt={3} flexWrap="wrap">
          {!isClientReviewer && (
            <Button
              size="lg"
              mt={3}
              mr={[0, 3, 6]}
              variant="outline"
              flex="1"
              onClick={onClickInviteOthers}
            >
              <MailIcon
                w={4}
                h={4}
                mr={3}
                __css={{
                  path: {
                    fill: 'ui.lighter.warning',
                  },
                }}
              />
              Invite Others
            </Button>
          )}
          <Button
            flex="1"
            size="lg"
            mt={3}
            onClick={(e: unknown) => handleSubmit(e as FormEvent<HTMLFormElement>)}
            variant="solid"
          >
            Submit Feedback
          </Button>
        </Flex>
      </>
    );
  }

  throw new Error(`View ${view} is not a valid view type`);
}
