import { Route } from 'react-router-dom';
import { CustomRoute } from 'global/components';
import { ClientReviewerRoleRouter } from 'talent-hub/shared/features/roles';
import { ManageCandidatesController } from 'talent-hub/shared/features/manage-candidates';
import { ScrollToTopOnPathChange, SwitchWithPageNotFound } from 'talent-hub/components';

export function ClientReviewerRouter() {
  return (
    <SwitchWithPageNotFound>
      <Route path="/candidates">
        <ManageCandidatesController />
      </Route>
      <Route path="/role">
        <ScrollToTopOnPathChange />
        <ClientReviewerRoleRouter />
      </Route>
      <CustomRoute
        path="/"
        conditionalRedirects={[{ condition: true, redirectURL: `/candidates` }]}
      />
    </SwitchWithPageNotFound>
  );
}
