import { Interview_Feedback_Rating_Choices_Enum } from 'global/types/hasura-tables.generated.types';

export const FeedbackRatinglabelValues = {
  [Interview_Feedback_Rating_Choices_Enum.StrongYes]: 'Strong Yes',
  [Interview_Feedback_Rating_Choices_Enum.Yes]: 'Yes',
  [Interview_Feedback_Rating_Choices_Enum.No]: 'No',
  [Interview_Feedback_Rating_Choices_Enum.StrongNo]: 'Strong No',
  default: '-',
} as const;

export type FeedbackRatinglabel =
  (typeof FeedbackRatinglabelValues)[keyof typeof FeedbackRatinglabelValues];

export type FeedbackStatus = 'Submitted' | 'Not Yet Submitted';
