import { Route } from 'react-router-dom';

import { CustomRoute } from 'global/components';
import { ScrollToTopOnPathChange, SwitchWithPageNotFound } from 'talent-hub/components';

import { RoleRouter } from 'talent-hub/shared/features/roles';
import { ManageCandidatesController } from 'talent-hub/shared/features/manage-candidates';
import { useTalentHubFlags } from 'talent-hub/utils';

import { DashboardController } from './dashboard';
import { ExploreCandidatesRouter } from './explore-candidates';
import { TerminalDirectoryController } from './terminal-directory';
import { MemberDirectoryController } from './member-directory';
import { DashboardController as NewDashboardController } from './new.dashboard';

export function ClientRouter() {
  const featureFlags = useTalentHubFlags();
  return (
    <SwitchWithPageNotFound>
      <Route path="/role">
        <RoleRouter />
      </Route>
      <Route path="/candidates">
        <ManageCandidatesController />
      </Route>
      <Route path="/members">
        <ScrollToTopOnPathChange />
        <MemberDirectoryController />
      </Route>
      <Route path="/directory">
        <ScrollToTopOnPathChange />
        <TerminalDirectoryController />
      </Route>
      <Route path="/explore-candidates">
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <ExploreCandidatesRouter />
      </Route>
      <Route exact path={[`/dashboard/candidate/:candidateID`, `/dashboard`]}>
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <NewDashboardController />
      </Route>
      <CustomRoute
        path="/"
        conditionalRedirects={[
          { condition: featureFlags['role-based-rehaul'], redirectURL: `/dashboard` },
        ]}
      >
        <ScrollToTopOnPathChange />
        <DashboardController />
      </CustomRoute>
    </SwitchWithPageNotFound>
  );
}
