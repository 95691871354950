import { Route, useRouteMatch } from 'react-router-dom';
import { ScrollToTopOnPathChange, SwitchWithPageNotFound } from 'talent-hub/components';
// * This is not expected, it is an exception since today these both roles are 99% the same.
import { ClientRouter } from 'talent-hub/role/client/Client.router';

import { ChangeOrganizationController } from './change-organization';
import { CustomerActivityDashboardController } from './customer-activity-dashboard';
import { EditOrganizationController } from './edit-organization';

function InteralRouter() {
  const { path } = useRouteMatch();

  return (
    <SwitchWithPageNotFound>
      <Route path={`${path}/customer-activity-dashboard`}>
        <ScrollToTopOnPathChange />
        <CustomerActivityDashboardController />
      </Route>
    </SwitchWithPageNotFound>
  );
}

export function RecruiterRouter() {
  return (
    <SwitchWithPageNotFound>
      <Route path="/change-organization">
        <ScrollToTopOnPathChange />
        <ChangeOrganizationController />
      </Route>
      <Route path="/edit-organization">
        <ScrollToTopOnPathChange />
        <EditOrganizationController />
      </Route>
      <Route path="/internal">
        <InteralRouter />
      </Route>
      <ClientRouter />
    </SwitchWithPageNotFound>
  );
}
