import type { ComponentSerializerProps } from 'talent-hub/utils';
import { to_matching_candidates } from 'talent-hub/shared/features';
import type {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Candidate_Education_Degree_Choices_Enum,
  Employment_Type_Choices_Enum,
  Job_Level_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types';
import { Job_Status_Choices_Enum } from 'global/types';
import type { GenerateJobFromAiQuery } from 'talent-hub/shared/features/roles/subfeatures/create-edit-job/data';
import { type SelectAiCandidatesMatchingJobMutation } from './data';
import { type PendingJob, type PostingWithTotals } from '../Dashboard.serializer';
import { type DashboardActiveRole } from './DashboardActiveRole';
import { type DashboardPendingRole } from './DashboardPendingRole';

export function dashboard_active_role_serializer(
  data: SelectAiCandidatesMatchingJobMutation | null | undefined,
  role: PostingWithTotals,
): ComponentSerializerProps<typeof DashboardActiveRole> {
  return {
    currentRoleCandidateMatches: to_matching_candidates(data, role.must_have_skills),
    name: role.name || '',
    id: role.id.toString(),
    totalActiveCandidates: role.totalActiveCandidates,
    openHeadCount: role.openHeadCount,
    totalHired: role.totalHired,
    totalCandidates: role.totalCandidates,
    candidates_to_review_per_role: role.candidates_to_review_per_role
      .filter((x) => x.id != null)
      .map(({ id, name, status, statusText }) => ({
        id: id as number,
        name,
        status,
        statusText: statusText || null,
      })),
    view_all_candidate_matches_link: role.explore_candidate_applied_filter_link,
  };
}

export function dashboard_pending_role_serializer(
  data: SelectAiCandidatesMatchingJobMutation | null | undefined,
  role: PendingJob,
): Omit<ComponentSerializerProps<typeof DashboardPendingRole>, 'generateJob'> {
  const selectAction = () => {
    if (role.status !== Job_Status_Choices_Enum.Draft) return 'VIEW_JOB';
    if (!role.rawRole.about && !role.rawRole.what_youll_bring && !role.rawRole.what_youll_do)
      return 'TELL_US_MORE';
    return 'EDIT_JOB';
  };

  return {
    currentRoleCandidateMatches: to_matching_candidates(data, role.must_have_skills),
    name: role.name || '',
    id: role.id.toString(),
    positions_count: role.positions,
    yoe: role.yoe,
    topSkills: role.topSkills,
    status: role.status,
    view_all_candidate_matches_link: role.explore_candidate_applied_filter_link,
    action: selectAction(),
  };
}

const areArraysEqual = (arr1: number[], arr2: number[]) =>
  arr1.length === arr2.length &&
  arr1.sort((a, b) => a - b).join(',') === arr2.sort((a, b) => a - b).join(',');

function serialize_upsert_delete(
  current_data: number[],
  new_data: number[],
  jobID: number,
  key: string,
) {
  const isEqual = areArraysEqual(current_data, new_data);
  if (!isEqual) {
    return {
      insertData: new_data.map((id) => ({
        [key]: id,
        job_id: jobID,
      })),
      skipField: false,
    };
  }
  return {
    insertData: [],
    skipField: true,
  };
}
export function serialize_update_job(
  ai_job: NonNullable<GenerateJobFromAiQuery['aiJob']>,
  current_data: PendingJob['rawRole'],
) {
  const { insertData: required_skills, skipField: skip_job_required_skill } =
    serialize_upsert_delete(
      current_data.job_required_skills.map(({ skill_id }) => skill_id),
      ai_job.mappedSkillsRequired?.map((skill) => skill?.value).filter((id) => id != null) || [],
      current_data.id,
      'skill_id',
    );

  const { insertData: nice_to_have_skills, skipField: skip_job_nice_to_have_skill } =
    serialize_upsert_delete(
      current_data.job_nice_to_have_skills.map(({ skill_id }) => skill_id),
      ai_job.mappedNiceToHaveSkills?.map((skill) => skill?.value).filter((id) => id != null) || [],
      current_data.id,
      'skill_id',
    );

  const { insertData: job_locations, skipField: skip_job_location } = serialize_upsert_delete(
    current_data.job_acceptable_locations.map(({ location: { id } }) => id),
    ai_job.mappedAcceptableLocations?.map((id) => id).filter((id) => id != null) || [],
    current_data.id,
    'location_id',
  );

  return {
    job_title: ai_job.jobTitle || current_data.title,
    job_type: (ai_job.roleType as unknown as Role_Choices_Enum) || current_data.role_type,
    employment_type:
      (ai_job.employmentType as unknown as Employment_Type_Choices_Enum) ||
      current_data.employment_type,
    level: (ai_job.level as unknown as Job_Level_Choices_Enum) || current_data.level,
    years_experience:
      (ai_job.yearsOfExperience as unknown as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum) ||
      current_data.min_years_of_experience,
    required_skills,
    skip_job_required_skill,
    nice_to_have_skills,
    skip_job_nice_to_have_skill,
    job_locations,
    skip_job_location,
    education_requirement:
      (ai_job.educationDegree as unknown as Candidate_Education_Degree_Choices_Enum) ||
      current_data.education_requirement,
    about: ai_job.aboutTheRole || current_data.about,
    what_youll_do: ai_job.whatYoullDo || current_data.what_youll_do,
    what_youll_bring: ai_job.whatYoullBring || current_data.what_youll_bring,
    job_id: current_data.id,
  };
}
